import { useEffect, useRef, useState } from "react";
import {Helmet} from "react-helmet";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Ratio from 'react-bootstrap/Ratio';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { useTranslation } from "react-i18next";
import moment from "moment";
import _ from "underscore";
import { Loader } from '@googlemaps/js-api-loader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookF, faTwitter, faLinkedin, faInstagram, faYoutube } from "@fortawesome/free-brands-svg-icons";
import Mustache from "mustache";
import './App.css';
import { AvailableLanguages } from "./i18nextConf";
import mapOptions from "./constant/MapOptions";
import getData from "./service/Api";
import { 
  StyledPage,
  StyledHeader,
  StyledSectionVideoBanner,
  StyledSectionGiantHeartsCaptivate,
  StyledSectionWhenAndWhere,
  StyledSectionSchedule,
  StyledSectionLiveFeed,
  StyledSectionPartnership,
  StyledSectionFaqs,
  StyledSectionFooter
} from "./styled";

export const nlToBr = (string) => {
  return string.split('\n').map((item, index) => {
    return (index === 0) ? item : [<br key={index} />, item]
  })
}

export const nlnlToP = (string) => {
  return string.split("\n\n").map((paragraph, i) => <p key={i}>{nlToBr(paragraph)}</p>)
}

function App() {
  const mapRef = useRef(null);
  const { t, i18n } = useTranslation();
  const [apiData, setApiData] = useState({ events: [], mapMarkers: null, faqs: null});
  const dateFormat = i18n.language.match(/^en.*/) ? "Do (ddd)" : "D日 (ddd)";
  const lang = i18n.language.match(/^en.*/) ? "en_us" : i18n.language.replace("-", "_").toLowerCase();

  const { events, mapMarkers, faqs, contentTexts } = apiData;
  // console.log(apiData);

  useEffect(() => {
    if (mapMarkers !== null) {
      const loader = new Loader({
        apiKey: "AIzaSyDl1XIc54k3ASwh1yvsR53a4IgFJrLtZQg",
        version: "weekly",
        libraries: ["places"]
      });
      loader
        .load()
        .then((google) => {
            const txtGoThere = t("go_there.label");
            const popupTemp = `<div style="height: auto; padding: 5px;">
              <a href="{{map_link}}" target="_blank">{{name}}</a>
            </div>`;
            const map = new google.maps.Map(
              mapRef.current, 
              mapOptions
            );
            _.each(mapMarkers, (location) => {
              // console.log(location);
              const name = location["name_".concat(lang)];
              const marker = new google.maps.Marker({
                title: name,
                position: {
                  lat: parseFloat(location.latitude),
                  lng: parseFloat(location.longitude),
                },
                icon: "/img/chubbyhearts-google-pin_54px.gif",
                map: map
              });
              marker.addListener("click", () => {
                new google.maps.InfoWindow({
                  content: Mustache.render(popupTemp, {name, ...location, txtGoThere})
                }).open(map, marker);
              });
            })
        })
        .catch(e => {
          console.error(e);
        });
    }
  }, [lang, mapMarkers, t]);

  const getApiData = () => { getData(({data}) => {
    setApiData(data);
  }) };

  useEffect(() => {
    if (apiData.events === null || apiData.mapMarkers === null) {
      getApiData();
    }
    const nextTimeCall = (3600 - new Date().getMinutes() * 60 - new Date().getSeconds() + 5) * 1000;
    const timeoutId = setTimeout(getApiData, nextTimeCall);
    return () => clearTimeout(timeoutId);
  }, [apiData]);

  const eventGroups = _.groupBy(events, ({ date }) => date);
  // console.log(eventGroups);
  const scheduleItem = (i) => {
    const date = moment("2024-02-14").locale(i18n.language.split("-")[0]).add(i, "d");
    const dateId = date.format("yyyy-MM-DD");
    const dateEvents = eventGroups[dateId] || [];
    return (
      <Col lg={4} md={6} sm={12} xs={12} className={["schedule-item", i > 3 ? "after-hearts" : "before-hearts"]} key={"schedule-".concat(i)}>
        <div>
          <p className="date">{date.format(dateFormat)}</p>
          <ul>{_.map(dateEvents, (event, j) => (
            <li key={"schedule_date_".concat(i, j)}>{event["text_".concat(lang)]}</li>
          ))}</ul>
        </div>
      </Col>
    );
  };

  const contentTextGroups = _.groupBy(contentTexts, ({ section }) => section)
  return (
    <>
      <Helmet>
        <meta name="description" content={t("giant_hearts_captivate_hong_kong_.label")} />
      </Helmet>
      <div className="App">
        <StyledPage>
          <a name="top" className="anchor">{t("top.label")}</a>
          <StyledHeader className="App-header">
            <Container>
              <Row>
                <Col>
                  <Navbar expand="lg">
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                      <Nav className="me-auto">
                        <Nav.Link href="#about">{t("about.label")}</Nav.Link>
                        <Nav.Link href="#schedule">{t("schedule.label")}</Nav.Link>
                        <Nav.Link href="#the-feed">{t("the_feed.label")}</Nav.Link>
                        <Nav.Link href="#faqs">{t("faqs.label")}</Nav.Link>
                        <NavDropdown title={AvailableLanguages[i18n.language]} id="language-nav-dropdown">
                          {_.map(AvailableLanguages, (name, code) => ( 
                            <NavDropdown.Item eventKey={code} key={code} href={"./"+code}>{name}</NavDropdown.Item>
                          ))}
                        </NavDropdown>
                      </Nav>
                    </Navbar.Collapse>
                  </Navbar>
                </Col>
              </Row>
              <Row>
                <Col>
                  <img id="header-banner" src="img/chubbyhearts-header-logo.gif" alt={t("chubby_hearts.label")} />
                </Col>
              </Row>
              <Row>
                <Col><p>{t("hong_kong.label")}</p></Col>
              </Row>
            </Container>
          </StyledHeader>
          <StyledSectionVideoBanner className="video-banner">
            <Container>
              <Ratio aspectRatio="16x9">
                <iframe 
                  width="100%" 
                  height="100%" 
                  src="https://www.youtube.com/embed/3r5DUMtH1fw?si=P1YnKNroWajxJlmk" 
                  title="YouTube video player" 
                  frameBorder="0" 
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                  allowFullScreen 
                />
              </Ratio>
            </Container>
          </StyledSectionVideoBanner>
          <StyledSectionGiantHeartsCaptivate className="giant-hearts-captivate">
            <Container>
              <Row>
                <Col>
                  <a name="about" className="anchor">{t("about.label")}</a>
                  <h2>{t("giant_hearts_captivate_hong_kong_.label")}</h2>
                </Col>
              </Row>
              <Row>
                <Col className="text-left">
                  {_.map(contentTextGroups['about'] || [], (contentText) => (
                    <>
                      {contentText["title_".concat(lang)] && <h3>{contentText["title_".concat(lang)]}</h3>}
                      {nlnlToP(contentText["content_".concat(lang)])}
                    </>
                  ))}
                </Col>
              </Row>
            </Container>
          </StyledSectionGiantHeartsCaptivate>
          <StyledSectionWhenAndWhere className="when-and-where">
            <Container>
              <Row>
                <Col>
                  <h2>{t("when_and_where.label")}</h2>
                </Col>
              </Row>
              <Row>
                <Col>
                  {_.map(contentTextGroups['when_and_where'] || [], (contentText) => (
                    <>
                      {contentText["title_".concat(lang)] && <h4>{contentText["title_".concat(lang)]}</h4>}
                      {nlnlToP(contentText["content_".concat(lang)])}
                    </>
                  ))}
                  <p><small>{t("hkdc_reserves_the_right_to_cancel_.label")}</small></p>
                </Col>
              </Row>
              {mapMarkers && (
                <Row>
                  <Col>
                    <div id="map" ref={mapRef}></div>
                  </Col>
                </Row>
              )}
            </Container>
          </StyledSectionWhenAndWhere>
          <StyledSectionSchedule className="schedule">
            <Container>
              <Row>
                <Col>
                  <a name="schedule" className="anchor">{t("schedule.label")}</a>
                  <h2>{t("schedule.label")}<br/>{t("february_2024.label")}</h2>
                </Col>
              </Row>
              <Container className="item-container" xs={{display: "none"}}>
                <Row>
                  {_.map(_.range(0, 4), scheduleItem)}
                  <Col id="schedule-heard" lg={4} md={6} sm={12} xs={12}>
                      <img id="schedule-heard-img" src="img/chubbyhearts-expanding-heart-balloon-300px.gif" alt="♥" key={"schedule-heart"}/>
                  </Col>
                  {_.map(_.range(4, 11), scheduleItem)}
                </Row>
              </Container>
            </Container>
          </StyledSectionSchedule>
          <StyledSectionLiveFeed className="live-feed">
            <Container>
              <Row>
                <Col>
                  <a name="the-feed" className="anchor">{t("the_live_feed.label")}</a>
                  <h2>{t("the_live_feed.label")}</h2>
                </Col>
              </Row>
              <Row>
                <Col>
                  <h3>{t("follow_chubbyhearts_on_instagrem_.label")}</h3>
                  <a target="_blank" href="https://www.instagram.com/explore/tags/CHUBBYheartsHK/" rel="noreferrer">{t("view_more.label")}</a>
                  <iframe src="https://widget.taggbox.com/149398" style={{ width: "100%", height: "300px", border: "none" }}></iframe>
                </Col>
              </Row>
            </Container>
          </StyledSectionLiveFeed>
          <StyledSectionPartnership className="partnership-with">
            <Container>
              <Row>
                <Col>
                  <h2>{t("created_in_partnership.label")}</h2>
                </Col>
              </Row>
              <Row className="partnership-content">
                <Col>
                  {_.map(contentTextGroups['created_in_partnership'] || [], (contentText) => (
                    <>
                      {contentText["title_".concat(lang)] && <h5>{contentText["title_".concat(lang)]}</h5>}
                      {nlnlToP(contentText["content_".concat(lang)])}
                    </>
                  ))}
                </Col>
              </Row>
            </Container>
          </StyledSectionPartnership>
          {faqs && (
            <StyledSectionFaqs className="faq">
              <Container>
                <Row>
                  <Col>
                    <a name="faqs" className="anchor">{t("frequently_asked_question.label")}</a>
                    <h2>{t("frequently_asked_question.label")}</h2>
                  </Col>
                </Row>
                <Row>
                  {_.map(faqs, (faq, i) => (
                    <Col lg={6} md={12} className="faq-item" key={i}>
                      <p className="question">{faq["question_".concat(lang)]}</p>
                      <p className="answer">{faq["answer_".concat(lang)]}</p>
                    </Col>
                  ))}
                </Row>
              </Container>
            </StyledSectionFaqs>
          )}
          <StyledSectionFooter>
            <Container>
              <Row className="partner-icons">
                <Col md={3} xs={6} className="hkdc-logo text-left">
                    <p>{t("presented_by.label")}</p>
                    <img src="img/HKDC logo RGB COLOUR.png" alt="HKDC logo" />
                </Col>
                <Col md={6} xs={8} className="designed-by text-left">
                    <p>{t("designed_by.label")}</p>
                    <img src="img/Anya-Hindmarch_1.png" alt="Anya Hindmarch" />
                </Col>
              </Row>
              <Row className="partner-icons">
                <Col className="3-logo text-left">
                    <p>{t("project_grant.label")}</p>
                    <img src="img/3-logo-c.png" alt="Mega ACE lock-up with CSTB and Govt Funded Programme logo" />
                </Col>
              </Row>
              <Row>
                <Col>
                  <a href="#about">{t("about.label")}</a>
                  <a href="#schedule">{t("schedule.label")}</a>
                  <a href="#the-feed">{t("the_feed.label")}</a>
                  |
                  <a href="#top">{t("back_to_top.label")}</a>
                </Col>
              </Row>
              <Row>
                <Col>
                  <img className="heart" src="img/chubbyhearts-beatingheart-balloon.gif" alt="♥" />
                </Col>
              </Row>
              <Row>
                <Col className="social-medias">
                  <a target="_blank" href="https://www.facebook.com/hkdesigncentre/" rel="noreferrer"><FontAwesomeIcon icon={faFacebookF}/></a>
                  <a target="_blank" href="https://twitter.com/hk_designcentre" rel="noreferrer"><FontAwesomeIcon icon={faTwitter}/></a>
                  <a target="_blank" href="https://www.linkedin.com/company/1181436/" rel="noreferrer"><FontAwesomeIcon icon={faLinkedin}/></a>
                  <a target="_blank" href="https://www.instagram.com/hkdesigncentre/" rel="noreferrer"><FontAwesomeIcon icon={faInstagram}/></a>
                  <a target="_blank" href="https://www.youtube.com/user/hkdcvideo" rel="noreferrer"><FontAwesomeIcon icon={faYoutube}/></a>
                </Col>
              </Row>
              <Row><Col className="copyright">
              <p>{t("copyright.label")}<a target="_blank" href="https://www.hkdesigncentre.org/" rel="noreferrer">{t("hong_kong_design_center.label")}</a>{t("all_right_reserved.label")}</p>
              </Col></Row>
            </Container>
          </StyledSectionFooter>
        </StyledPage>
      </div>
    </>
  );
}

export default App;
