import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-xhr-backend";
// import LanguageDetector from "i18next-browser-languagedetector";
import moment from "moment";

moment.locale("zh", {
  weekdays : '日_一_二_三_四_五_六'.split('_').map((t) => "星期".concat(t)),
  weekdaysShort : '日_一_二_三_四_五_六'.split('_').map((t) => "星期".concat(t)),
  weekdaysMin : '日_一_二_三_四_五_六'.split('_'),
});

const fallbackLng = ["en"];
export const AvailableLanguages = {
  en: "English",
  "zh-HK": "繁體中文",
  "zh-CN": "简体中文",
};

const PathLanguageDetector = {
  type: 'languageDetector',
  async: false,
  init: function(services, detectorOptions, i18nextOptions) {},
  detect: function(callback) {
    const path = window.location.pathname.toLowerCase();
    for(const code in AvailableLanguages) {
      if(path.match("^/" + code.toLowerCase())){
        return code;
      }
    }
    const browserLang = window.navigator.language;
    if (browserLang.match("^zh-CN")) {
      window.location.href = "./zh-CN";
    } else if (browserLang.match("^zh")){
      window.location.href = "./zh-HK";
    } else {
      window.location.href = "./en";
    }
    return "en";
  },
  cacheUserLanguage: function(lng) { // optional since v22.3.0
  }
};

i18n
  .use(Backend) // load translations using http (default                                               public/assets/locals/en/translations)
  .use(PathLanguageDetector) // detect user language
  .use(initReactI18next) // pass the i18n instance to react-i18next.
  .init({
    fallbackLng, // fallback language is english.

    detection: {
      checkWhitelist: true, // options for language detection
    },

    debug: false,

    whitelist: Object.keys(AvailableLanguages),

    interpolation: {
      escapeValue: false, // no need for react. it escapes by default
    },
    react: {
      useSuspense: false, //   <---- this will do the magic
    },
  });

export default i18n;
