import styled from "styled-components";

const mainColor = "#73253d";
const secondColor = "#999999";
const sectionPaddingTop = "25px 50px";

export const StyledPage = styled.div`
    h2 {
        display: block;
        font-size: 35px;
        line-height: 1.2;
        margin-block-start: 0.335em;
        margin-block-end: 0.335em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        font-weight: bold;
    }
    .text-left {
        text-align: left;
    }
    .text-right {
        text-align: right;
    }
    .text-center {
        text-align: right;
    }
    .text-justify {
        text-align: justify;
    }
`;

export const StyledHeader = styled.header`
    .nav-link {
        margin: 0 10px;
        color: #999;
    }
    .navbar {
        justify-content: center;
    }
    .nav-link:hover {
        color: ${mainColor};
    }
    .navbar-collapse {
        flex-grow: 0;
    }
    .dropdown {
        position: absolute;
        right: 0px;
    }
    #header-banner {
        width: 400px;
        max-width: 80%;
    }
    .container {
        background-color: #efece6;
        font-size: 15px;
        color: ${mainColor};
    }
`;

export const StyledSectionVideoBanner = styled.section`
    .container {
        padding-left: 0px;
        padding-right: 0px;
    }
`;

export const StyledSectionGiantHeartsCaptivate = styled.section`
    .container {
        background-color: #efece6;
        padding: ${sectionPaddingTop};
    }
`;

export const StyledSectionWhenAndWhere = styled.section`
    .container {
        background-color: rgb(229, 227, 223);
        padding: ${sectionPaddingTop};
        #map {
            width: 100%;
            height: 500px;
        }
    }
`;

export const StyledSectionSchedule = styled.section`
    h2 {
        color: #999;
        padding: 14px 0;
    }
    .container {
        background-color: #efece6;
    }
    .item-container {
        padding: 38px 80px;
    }
    #schedule-heard {
        padding-left: 0px;
        padding-right: 0px;
        border-bottom: 1px solid ${mainColor};
        border-right: 1px solid ${mainColor};
        img {
            width: 100%;
        }
    }
    .item-container .row > div {
        min-height: 200px;
    }
    @media (min-width: 768px ) {
        .item-container .row > div {
            min-height: 280px;
        }
    }
    @media (min-width: 992px ) {
        .item-container .row > div {
            min-height: 266.66px;
        }
    }
    @media (min-width: 1400px ) {
        .item-container .row > div {
            min-height: 386.66px;
        }
    }
    .schedule-item {
        padding: 27px;
        border-bottom: 1px solid ${mainColor};
        border-right: 1px solid ${mainColor};

        .date {
            padding-bottom: 8px;
            font-size: 35px;
        }
        ul {
            margin: 0 20px;
        }
    }
    @media (max-width: 1200px) {
        .schedule-item {
            padding: 12px;
    
            .date {
                padding-bottom: 8px;
                font-size: 24px;
            }
            ul {
                margin: 0px;
            }
        }
    }
    @media (max-width: 767px) {
        .schedule-item {
            border-right: none;
        }
        .schedule-item:nth-last-child(-n+1) {
            border-bottom: none;
        }
        #schedule-heard {
            border-right: none;
        }
    }
    @media (max-width: 991px) {
        #schedule-heard {
            display: none;
        }
        .before-hearts:nth-child(2n), .after-hearts:nth-child(2n+1) {
            border-right: none;
        }
        .schedule-item:nth-last-child(-n+1) {
            border-bottom: none;
        }
        .ratio {
            min-height: 250px;
            &::before {
                display: none;
            }
            &>*{
                height: auto;
                position: relative;
            }
        }
    }
    @media (min-width: 991px) {
        .schedule-item:nth-child(3n) {
            border-right: none;
        }
        .schedule-item:nth-last-child(-n+3) {
            border-bottom: none;
        }
        .schedule-item:nth-last-child(-n+2) {
            border-bottom: none;
        }
        .schedule-item:nth-last-child(-n+1) {
            border-bottom: none;
        }
    }
`;

export const StyledSectionLiveFeed = styled.section`
    .container {
        padding: ${sectionPaddingTop};
        iframe {
            margin-top: 20px;
        }
    }
`;

export const StyledSectionPartnership = styled.section`
    .container {
        padding: ${sectionPaddingTop};
        background-color: #efece6;
        .partnership-content {
            text-align: left;
        }
    }
`;
export const StyledSectionPresentedGrant = styled.section`
.container {
    background-color: #efece6;
    .partner-icons {
        > div {
            padding: ${sectionPaddingTop};
        }
        font-size: 0.7em;
        p{
            text-align: left
        }
        img {
            max-width: 100%;
        }

    }
}
`;

export const StyledSectionFaqs = styled.section`
    .container {
        padding: 60px 130px;
        background: #e72640;
        color: #efece6;
        .faq-item {
            text-align: left;
            padding: 0px 20px;
            .question {
                display: block;
                min-heigh: 60px;
                padding: 30px 0 6px;
                font-size: 26px;
                line-height: 1.1;
                margin-right: 42px;
            }
        }
    }
    @media (max-width: 768px) {
        .container {
            padding: 30px 30px;
            background: #e72640;
            color: #efece6;
            .faq-item {
                text-align: left;
                padding: 0px 20px;
                .question {
                    display: block;
                    min-heigh: 60px;
                    padding: 30px 0 6px;
                    font-size: 26px;
                    line-height: 1.1;
                    margin-right: 42px;
                }
            }
        }
    }
`;

export const StyledSectionFooter = styled.footer`
    a {
        margin: 0px 10px;
    }
    img.heart {
        width: 90px;
        margin-top: 20px;
    }
    .container {
        padding: 0 25px;
        background-color: #efece6;
        .partner-icons {
            > div {
                padding: 25px;
            }
            font-size: 0.7em;
            p{
                text-align: left
            }
            img {
                max-width: 100%;
            }
        }
    }
    .social-medias {
        margin-top: 20px;
        font-size: 2.0em;
        a {
            display: inline-block;
            color: ${secondColor};
            border-style: solid;
            border-width: 2px;
            border-color: ${secondColor};
            width: 70px;
            height: 70px;
            padding: 12px;
            border-radius: 35px;
            &:hover {
                color: ${mainColor};
                border-color: ${mainColor};
            }
        }
    }
    @media ( max-width: 767px ) {
        .social-medias {
            font-size: 1.5em;
            a {
                width: 50px;
                height: 50px;
                padding: 10px;
                border-radius: 25px;
            }
        }
    }
    .copyright {
        color: ${secondColor};
        margin-top: 20px;
        font-size: 0.75em;
    }
`;

export default { 
    StyledPage,
    StyledHeader,
    StyledSectionVideoBanner,
    StyledSectionGiantHeartsCaptivate,
    StyledSectionWhenAndWhere,
    StyledSectionSchedule,
    StyledSectionLiveFeed,
    StyledSectionPartnership,
    StyledSectionFaqs,
    StyledSectionFooter
};
